import turn14StoresDefaults from '../bigcommerce-turn14-stores/config.js';

export default {
  includes: ['bigcommerce-turn14-stores'],
  ...turn14StoresDefaults,
  Widgets: [
    ...turn14StoresDefaults.Widgets.filter(
      (w) => !['SingleVehicleGarage', 'SingleVehicleGarage_mobile'].includes(w.name),
    ),
    {
      name: 'SingleVehicleGarage',
      location: {
        replace: 'header .navigationButtons.header-desktop .button.button--primary-light',
        class: 'cm_single-vehicle-garage button button--primary-light',
      },
      template: 'fitmentSearch/singleVehicleGarage',
    },
    {
      name: 'SingleVehicleGarage_mobile',
      type: 'SingleVehicleGarage',
      location: {
        replace: 'header .navigationButtons.header-mobile .button.button--primary-light',
        class: 'cm_single-vehicle-garage_mobile button button--primary-light',
      },
      template: 'fitmentSearch/singleVehicleGarage',
    },
  ],
};
