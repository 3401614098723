const baseFitmentFields = ['Year', 'Make', 'Model'];
const extraFitmentFields = [];

const categorySelectionPageUrl = '/categories';
const brandSelectionPageUrl = '/brands';

const fieldsForRelated = ['category_full'];
function getSelection(itemData) {
  return fieldsForRelated.flatMap((field) => {
    const value = itemData[field];
    return !value
      ? []
      : Array.isArray(value)
        ? value.map((term) => ({ field, term }))
        : { field, term: value };
  });
}

function showVehicleWidget(e, trigger, selectorToHide) {
  const { document, innerWidth } = window;
  const isMobile = innerWidth < 800;
  const elementToHide = document.querySelector(selectorToHide);
  const clickedElement = e.target;

  if (clickedElement.closest(trigger)) {
    elementToHide.classList.toggle('cm_vehicle-widget-hide');
    elementToHide.classList.remove('cm_vehicle-widget-init');

    if (isMobile) {
      document.body.classList.add('cm_no-scroll');
      document.documentElement.classList.add('cm_no-scroll');
    }
  }

  if (
    !elementToHide.classList.contains('cm_vehicle-widget-hide') &&
    (clickedElement.closest('.close') ||
      clickedElement.closest('.cm_vehicle-widget_button__go') ||
      (!clickedElement.closest(trigger) && !clickedElement.closest(selectorToHide)) ||
      (isMobile && clickedElement.classList.contains('VehicleWidgetDialog')))
  ) {
    elementToHide.classList.add('cm_vehicle-widget-hide');
    document.body.classList.remove('cm_no-scroll');
    document.documentElement.classList.remove('cm_no-scroll');
  }
}

const AfterInit = () => {
  window.addEventListener('click', (e) => {
    showVehicleWidget(e, '.cm_single-vehicle-garage', '#cm_HeaderVehicleWidget');
    showVehicleWidget(e, '.cm_single-vehicle-garage_mobile', '#cm_HeaderVehicleWidget_mobile');
  });
};

export default {
  platform: 'bigcommerce',
  AfterInit,
  page: {
    getPageType: (defaults) =>
      window.location.pathname.includes(categorySelectionPageUrl) ||
      window.location.pathname.includes(brandSelectionPageUrl)
        ? 'parts'
        : defaults.getter(),
  },
  SearchRequestDefaults: {
    pageSize: 36,
    sort: window.Convermax.config?.defaultSort ?? 'relevance',
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: '/categories', field: 'category' },
      { pathname: '/brands', field: 'brand_name' },
    ],
    isAutoRedirectDisabled: true,
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: 'body.page-category .page.plp-page',
      template: 'SearchPage',
      visibleIf: () => !window.location.pathname.includes(categorySelectionPageUrl),
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'price': 'Price: Ascending',
        'price:desc': 'Price: Descending',
      },
    },
    {
      name: 'FacetPanel',
    },
    {
      name: 'SearchResult',
    },
    {
      name: 'SearchBox',
      type: 'SearchBoxDialogButton',
      location: 'header .search-container.header-desktop',
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'MobileSearchBox',
      type: 'SearchBoxDialogButton',
      location: 'header .search-container.header-mobile',
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: '.vehical-widget-select-container',
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 450,
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: {
        lastChildOf: 'header .navigationButtons.header-desktop',
        class:
          'cm_dialog cm_dialog-dropdown VehicleWidgetDialog vehicle-dialog-header cm_vehicle-widget-hide cm_mobile-hide',
      },
      template: 'fitmentSearch/headerVehicleWidget',
      columnBreakpoint: 9999,
    },
    {
      name: 'HeaderVehicleWidget_mobile',
      type: 'VehicleWidget',
      location: {
        lastChildOf: 'header .navigationButtons.header-mobile',
        class:
          'cm_dialog VehicleWidgetDialog vehicle-dialog-header cm_vehicle-widget-hide cm_desktop-hide cm_vehicle-widget-init',
      },
      template: 'fitmentSearch/headerVehicleWidget',
      columnBreakpoint: 9999,
    },
    {
      name: 'SingleVehicleGarage',
      location: {
        replace: 'header .header-desktop .button.button--primary-dark',
        class: 'cm_single-vehicle-garage button button--primary-dark',
      },
      template: 'fitmentSearch/singleVehicleGarage',
    },
    {
      name: 'SingleVehicleGarage_mobile',
      type: 'SingleVehicleGarage',
      location: {
        replace: 'header .navigationButtons.header-mobile .button.button--secondary',
        class: 'cm_single-vehicle-garage_mobile button button--secondary',
      },
      template: 'fitmentSearch/singleVehicleGarage',
    },
    {
      name: 'Garage',
      location: {
        replace: 'header .navigationIconButtons.header-desktop .header-desktop-garage',
        class: 'icon-text-container cm_garage-desktop',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'MobileGarage',
      type: 'Garage',
      location: {
        replace: 'header .header-garage.header-mobile',
        class: 'header-garage header-mobile link link--with-icon cm_garage-mobile',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'VerifyFitment',
      location: { replace: 'body.page-product .productView .chooseVehicle' },
      template: 'fitmentSearch/verifyFitment',
      initCollapsed: true,
      columnBreakpoint: 650,
    },
    {
      name: 'VerifyFitment_status',
      type: 'VerifyFitment',
      location: { lastChildOf: 'body.page-product .productView .productView-product .labelHolder' },
      template: 'fitmentSearch/verifyFitmentStatus',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: {
        selector: 'body.page-category .page.plp-page',
        class: 'cm_vehicle-categories__category',
      },
      template: 'facetTiles/hierarchicalContainer',
      visibleIf: () => window.location.pathname.includes(categorySelectionPageUrl),
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: '#cm-brand-list',
      template: 'facetTiles/alphabeticalContainer',
      view: 'grid',
    },
    {
      name: 'FitmentTable',
      location: {
        insertAfter: 'body.page-product .tabs-contents #tab-related',
        class: 'tab-content has-jsContent',
        id: 'tab-fitments',
      },
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FitmentTableTab',
      type: 'FitmentTable',
      location: { lastChildOf: 'body.page-product .tabs', class: 'tab', wrapper: 'li' },
      template: 'fitmentSearch/fitmentTableTab',
    },
    {
      name: 'RelatedItems',
      location: 'body.page-product #tab-related',
      template: 'product/relatedItems',
      count: 6,
      getSelection,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
    },
    {
      name: 'VehicleWidgetDialog',
      template: 'fitmentSearch/dialog',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
  ],
};
