
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(showFitBadge, items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                return _createElement('li', { 'className': 'product' }, _createElement('article', { 'className': 'search-card' }, _createElement('figure', { 'className': 'card-figure' }, _createElement('a', {
                    'href': this.custom_url,
                    'className': 'card-figure__link',
                    'aria-label': 'this.removeHTML(this.name)',
                    'data-event-type': 'product-click'
                }, _createElement('div', { 'className': 'card-img-container' }, _createElement('img', {
                    'data-sizes': 'auto',
                    'src': this.imageOrDefault(this.images_image_standard1 ?? this.images_image_thumbnail1),
                    'data-src': this.imageOrDefault(this.images_image_standard1 ?? this.images_image_thumbnail1),
                    'alt': this.removeHTML(this.name),
                    'title': this.removeHTML(this.name),
                    'className': 'card-image lazyautosizes ls-is-cached lazyloaded',
                    'sizes': '371px'
                }))), _createElement('div', { 'className': 'card-body' }, _createElement('h3', { 'className': 'card-title' }, _createElement('a', Object.assign({}, {
                    'aria-label': 'this.removeHTML(this.name)',
                    'href': this.custom_url,
                    'data-event-type': 'product-click'
                }, { dangerouslySetInnerHTML: { __html: this.name } }))), _createElement('div', { 'className': 'labelHolder' + (showFitBadge ? ' showFitBadge' : '') }, showFitBadge && this.Fitment === 'Vehicle Specific' ? _createElement('div', {
                    'className': 'badge badge-success',
                    'key': '1010'
                }, _createElement('span', { 'className': 'material-icons' }, 'check_circle_outline'), _createElement('span', { 'className': 'badge-text' }, 'Fits your Vehicle')) : null), _createElement('div', {
                    'className': 'card-text',
                    'data-test-info-type': 'price'
                }, this.retail_price > this.price ? _createElement('div', {
                    'className': 'price-section price-section--withoutTax rrp-price--withoutTax',
                    'key': '1355'
                }, _createElement('h4', {}, 'MSRP: ', this.formatPrice(this.retail_price)), _createElement('span', {
                    'data-product-rrp-price-without-tax': true,
                    'className': 'price price--rrp'
                })) : null, this.on_sale ? _createElement('div', {
                    'className': 'price-section price-section--withoutTax non-sale-price--withoutTax',
                    'key': '1648'
                }, _createElement('span', {}, 'Was:'), _createElement('h4', {
                    'data-product-non-sale-price-without-tax': true,
                    'className': 'price price--non-sale'
                }, '\n              ', this.formatPrice(this.regular_price), '\n            ')) : null, _createElement('div', { 'className': 'price-section price-section--withoutTax' }, _createElement('span', { 'className': 'price-label' }), _createElement('h4', {
                    'data-product-price-without-tax': true,
                    'className': 'price price--withoutTax'
                }, '\n              ', this.formatPrice(this.price), '\n            '))), _createElement('p', mergeProps({ 'className': 'description' }, { dangerouslySetInnerHTML: { __html: this.description } }))))));
            }, { count: undefined })];
    }
    function scopeShowFitBadge2() {
        var showFitBadge = false;
        return _createElement.apply(this, [
            'div',
            { 'className': 'productGrid cm_related-products-list cmRepeater_items' },
            _map(this.items, repeatItems1.bind(this, showFitBadge))
        ]);
    }
    return _createElement('div', { 'className': 'cm_related-products' }, scopeShowFitBadge2.apply(this, []));
}
        export const componentNames = []