export default {
  BUTTON_PRIMARY_CLASS: 'button button--primary',
  BUTTON_SECONDARY_CLASS: 'button button--secondary',

  SEARCH_BOX_INPUT_CLASS: 'form-input',
  SEARCH_BOX_CLEAR_BUTTON: 'Clear',

  PRODUCT_REPEATER_CLASS: 'productGrid',
  RELATED_PRODUCT_LIST_CLASS: 'productGrid',

  SELECT_YOUR_VEHICLE: 'Select Vehicle',

  GARAGE_ICON: '<rt-virtual template="icons/garage" />',
  GARAGE_TITLE: 'My Garage',
};
